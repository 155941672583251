import React, { FC, useEffect, useRef } from 'react';
import { Button, CardContent, Grid, Paper, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { Form, FormRenderProps } from 'react-final-form';
import toast from 'react-hot-toast';
import { CleanButton } from 'src/components/clean/button';
import { E2eLogicObjectsWithAasoParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import LoadingState from 'src/e2e-redesign/components/LoadingState';
import { e2eAppSectionLogic_functionsAndVals } from 'src/e2e-redesign/views/application/logic/e2e-app-logic';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes } from 'src/enums/routes.enums';
import { useFormErrorScroll } from 'src/hooks/use-form-error-scroll';
import { FieldDetails } from 'src/interfaces/application.interfaces';
import { validateFormValues } from 'src/pages/application/application-logic';
import AbandonedFormWrapper from 'src/pages/application/components/abandoned-form-wrapper';

import AddressValidationModal from './components/address-validation-modal';

interface ApplicationFormSectionProps {
  routingPackage: E2eLogicObjectsWithAasoParams;
}
const ApplicationFormSection: FC<any> = (props: ApplicationFormSectionProps) => {
  const routingPackage = props.routingPackage;
  const { e2eStore, navigate } = routingPackage;
  const aaso = e2eStore.aaso;
  const fnv = e2eAppSectionLogic_functionsAndVals(props.routingPackage);
  const {
    validationSchema,
    e2eFormPath,
    showLoading,
    formValues,
    updatedFields,
    required_fields,
    openModal,
    canSubmit,
    modalAddress,
    locations,
  } = fnv.v;
  const {
    handleSubmitForm,
    handleAutoGen,
    setFormValues,
    handleNavigateBack,
    setOpenModal,
    setForceAddressVerification,
  } = fnv.fn;
  const DevAdminOnlyFormDataViewer = (props: any) => {
    const { errors, values } = props;

    return (
      <Paper elevation={3} style={{ backgroundColor: '#f5f5f5', border: '1px solid #ccc' }}>
        <Typography variant={'h5'} style={{ color: '#1976d2', marginBottom: '15px' }}>
          ONLY SHOWS ON DEV
        </Typography>
        <Typography variant={'body1'} style={{ fontStyle: 'italic', fontWeight: 'bold', marginBottom: '20px' }}>
          If you used auto-gen and are seeing the grey button, click on any input and press tab.
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6} className={'fs-mask'}>
            <Typography variant={'h6'}>Form Data</Typography>
            <Typography
              component={'pre'}
              className={'fs-mask'}
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: '#e8eaf6',
                padding: '10px',
                borderRadius: '4px',
                overflowX: 'hidden',
                boxSizing: 'border-box',
                maxWidth: '100%',
              }}
            >
              {JSON.stringify(values, null, 2)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant={'h6'}>Form Errors</Typography>
            <Typography
              component={'pre'}
              style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                backgroundColor: '#ffebee',
                padding: '10px',
                borderRadius: '4px',
                overflowX: 'hidden',
                boxSizing: 'border-box',
                maxWidth: '100%',
              }}
            >
              {JSON.stringify(errors, null, 2)}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  // Original function before modification
  // const notifyUserIfErrors = (e: React.MouseEvent, valid: boolean, submitting: boolean, errors: any) => {
  //   e.preventDefault(); // Prevent default form submission

  //   if (!valid && !isEmpty(errors)) {
  //     // Show error toast/alert if form is invalid
  //     try {
  //       // Get up to 5 error keys
  //       const errorKeys = Object.keys(errors).slice(0, 5);

  //       if (errorKeys.length === 0) {
  //         throw Error('no errors found');
  //       }

  //       // Show a toast for each error (up to 5)
  //       errorKeys.forEach((errorKey) => {
  //         const foundKey = updatedFields.find((f) => f.id === errorKey);
  //         if (foundKey) {
  //           toast.error(`${foundKey.default_details.label}: ${errors[errorKey]}`, {
  //             duration: 5000,
  //             // Add some delay between toasts to prevent overlap
  //             // You may need to adjust this based on your toast library
  //             id: `error-${errorKey}`, // Add unique ID to prevent duplicate toasts
  //           });
  //         }
  //       });
  //     } catch (e) {
  //       console.log('no form errors found');
  //       toast.error('Please fix form errors before submitting');
  //     }
  //     return;
  //   }

  //   // If we get here, form is valid - trigger form submission
  //   const form = e.currentTarget.closest('form');
  //   if (form) {
  //     // Create and dispatch submit event
  //     const submitEvent = new Event('submit', { bubbles: true, cancelable: true });
  //     form.dispatchEvent(submitEvent);
  //   }
  // };

  // modified line
  const scrollHandlerRef = useRef<((e: React.MouseEvent | React.FormEvent) => void) | null>(null);

  // Modified function
  const notifyUserIfErrors = (e: React.MouseEvent, valid: boolean, submitting: boolean, errors: any) => {
    e.preventDefault(); // Prevent default form submission
    if (!valid && !isEmpty(errors)) {
      // Show error toast/alert if form is invalid

      // modified line
      if (scrollHandlerRef.current) {
        scrollHandlerRef.current(e);
      }

      try {
        // Get up to 5 error keys
        const errorKeys = Object.keys(errors).slice(0, 5);

        if (errorKeys.length === 0) {
          throw Error('no errors found');
        }

        // modified - commented out. Only showing the first error which is the one we scroll to.
        // Show a toast for each error (up to 5)
        // errorKeys.forEach((errorKey) => {
        //   const foundKey = updatedFields.find((f) => f.id === errorKey);
        //   if (foundKey) {
        //     toast.error(`${foundKey.default_details.label}: ${errors[errorKey]}`, {
        //       // modified line - changed from 5000 to 3000
        //       duration: 3000,
        //       // Add some delay between toasts to prevent overlap
        //       // You may need to adjust this based on your toast library
        //       id: `error-${errorKey}`, // Add unique ID to prevent duplicate toasts
        //     });
        //   }
        // });
        // Show toast only for first error
        const firstErrorKey = errorKeys[0];
        const foundKey = updatedFields.find((f) => f.id === firstErrorKey);
        if (foundKey) {
          toast.error(`${foundKey.default_details.label}: ${errors[firstErrorKey]}`, {
            duration: 3000,
            id: `error-${firstErrorKey}`,
          });
        }
      } catch (e) {
        console.log('no form errors found');
        toast.error('Please fix form errors before submitting');
      }
      return;
    }

    // If we get here, form is valid - trigger form submission
    const form = e.currentTarget.closest('form');
    if (form) {
      // Create and dispatch submit event
      const submitEvent = new Event('submit', { bubbles: true, cancelable: true });
      form.dispatchEvent(submitEvent);
    }
  };

  const handleButton = (valid: boolean, submitting: boolean, errors: any) => {
    switch (aaso.current_stage) {
      case ApplicationStage.FORM_SUBMITTED:
      case ApplicationStage.LENDER_SELECTED:
      case ApplicationStage.COMPLETED:
        return (
          <CleanButton
            fullWidth
            sx={{ maxWidth: { sm: '25%' } }}
            variant="contained"
            color="primary"
            onClick={() => navigate(`../${E2ERoutes.PrimeLenderInformationReview}`)}
          >
            Move Forward
          </CleanButton>
        );
      case ApplicationStage.STARTED:
      case ApplicationStage.DISCLOSURE_PRIVACY:
      case ApplicationStage.DISCLOSURE_TERMS:
      case ApplicationStage.DISCLOSURE_LENDERS:
      case ApplicationStage.FORM_STARTED:
      default:
        return (
          <CleanButton
            fullWidth
            // disabled={!valid || submitting}
            sx={{ maxWidth: { sm: '25%' } }}
            variant="contained"
            color="primary"
            onClick={(e) => notifyUserIfErrors(e, valid, submitting, errors)}
            // type={'submit'}
          >
            SUBMIT
          </CleanButton>
        );
    }
  };

  const updateAddress = (currentValues: any) => {
    const component = modalAddress.components;
    // currentValues.address_number = component.primaryNumber;
    // currentValues.address_street_name = component.streetName;
    // currentValues.apt_suite = component.secondaryNumber
    //   ? `${component.secondaryNumber} ${component.secondaryDesignator}`
    //   : '';
    // currentValues.city = component.cityName;
    // currentValues.state_name = st?.state_name;
    // currentValues.zip_code = component.zipCode;

    const st = locations.find((l) => l.state_acronym === component.state);
    // const apt = component.secondaryNumber ? `${component.secondaryNumber}  ${component.secondaryDesignator}` : '';
    const apt = component.secondaryNumber;
    const streetSuffix = component.streetSuffix;
    const streetDirection = component.streetPostdirection;
    const streetPredirection = component.streetPredirection;
    let streetName = '';
    if (streetPredirection && streetPredirection !== 'undefined') {
      streetName += `${streetPredirection} `;
    }
    streetName += component.streetName;
    if (streetSuffix) {
      streetName += ` ${streetSuffix}`;
    }
    if (streetDirection) {
      streetName += ` ${streetDirection}`;
    }

    return {
      address_number: component.primaryNumber,
      address_street_name: streetName,
      apt_suite: apt,
      city: component.cityName,
      state_name: st?.state_name,
      zip_code: component.zipCode,
    };
  };
  return (
    <>
      {showLoading && <LoadingState title={'Processing'} paragraph={'We are processing your application'} />}
      {!showLoading && e2eFormPath && (
        <Form
          key={formValues?.sql_id}
          // modified - this is to fix the issue where the field is not being touched when the user blurs the field.  This makes it show the error state.
          mutators={{
            setFieldTouched: ([field], state, { changeValue }) => {
              changeValue(state, field, () => true);
            },
          }}
          // end modified
          enableReinitialize
          validate={validateFormValues(validationSchema)}
          // validateOnBlur={true}
          initialValues={formValues}
          onSubmit={(values) => {
            if (canSubmit) {
              setFormValues(values);
              handleSubmitForm(values);
            } else {
              toast.error(
                'You have already submitted the form for this path. If you would like to edit your form, contact your sales rep.',
              );
            }
          }}
          render={(formRenderProps: FormRenderProps) => {
            const { handleSubmit, errors, valid, submitting, values, form } = formRenderProps;

            // modified line
            const { registerFieldRef, handleSubmitWithErrors } = useFormErrorScroll(formRenderProps);

            // modified line
            scrollHandlerRef.current = handleSubmitWithErrors;

            return (
              <form onSubmit={handleSubmit} noValidate>
                {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
                  <Button
                    color="secondary"
                    onClick={() => handleAutoGen(formRenderProps)}
                    variant="contained"
                    style={{ marginBottom: 30 }}
                  >
                    Auto-Gen
                  </Button>
                )}
                <AbandonedFormWrapper
                  updatedFields={updatedFields}
                  fieldDetailType={FieldDetails.final_details}
                  formRenderProps={formRenderProps}
                  required_fields={required_fields}
                  // modified line
                  registerFieldRef={registerFieldRef}
                />
                {/* TODO ADD BACK AFTER LENDING CLUB */}
                {/* {process.env.REACT_APP_ENVIRONMENT !== 'production' && ( */}
                {/*   <DevAdminOnlyFormDataViewer values={values} errors={errors} /> */}
                {/* )} */}
                <Stack direction={'column'} spacing={3} px={5}>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ marginTop: 50 }}
                  >
                    <Typography variant="h5" paragraph mt={2}>
                      IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ marginTop: 50 }}
                  >
                    <Typography variant="body1" paragraph mt={2}>
                      To help the government fight the funding of terrorism and money laundering activities, Federal law
                      requires all financial institutions to obtain, verify, and record information that identifies each
                      person who opens an account.
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ marginTop: 50 }}
                  >
                    <Typography variant="body1" paragraph mt={2}>
                      What this means for you: When you open an account, or when you are added to an existing account,
                      we will ask for your name, permanent physical street address, date of birth, and other
                      information, which may include social security number or TIN, that will allow us to identify you.
                      We may also ask to see your driver's license or other identifying documents. You may also be asked
                      to provide such information at other times upon request of the Bank. We will accept a post office
                      box only as a mailing address.
                    </Typography>
                  </Stack>
                  <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ marginTop: 50 }}
                  >
                    {/* <CleanButton */}
                    {/*   sx={{ color: '#243F8F', width: '100%', maxWidth: { sm: '25%' } }} */}
                    {/*   fullWidth */}
                    {/*   style={{ maxWidth: '25%' }} */}
                    {/*   variant="text" */}
                    {/*   onClick={handleNavigateBack} */}
                    {/* > */}
                    {/*   BACK */}
                    {/* </CleanButton> */}
                    {aaso && handleButton(valid, submitting, errors)}
                  </Stack>
                </Stack>
                <AddressValidationModal
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  form={formValues}
                  modalAddress={modalAddress}
                  onProceed={() => {
                    // setForceAddressVerification(true);
                    // Promise.resolve().then(() => {
                    //   handleSubmitForm(formValues);
                    // });
                    const newAddress: any = updateAddress(formValues);
                    const keys = Object.keys(newAddress);
                    keys.forEach((k) => {
                      form.change(k, newAddress[k]);
                    });
                    // formValues;
                    setOpenModal(false);
                  }}
                />
              </form>
            );
          }}
        />
      )}
    </>
  );
};

export default ApplicationFormSection;
