import { useEffect, useState } from 'react';
import { API_E2E_GET_AASO_BY_APPLICANT } from 'src/api/e2e-request-objects';
import {
  E2eBaseCallDetailsI,
  e2eGetBaseAuthenticatedRequests,
  e2eGetBasePublicRequests,
} from 'src/api/query-logic/e2e-query-base-calls';
import { apiRequest } from 'src/api/request-handler';
import { e2eConfigLogic_buildStoreLenderLogoObjects } from 'src/e2e-redesign/business-logic/e2e-configuration-object-logic';
import { E2eLogicObjectsWithParams } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  E2eRoutingArgsI,
  handleCreateAccountRoutingLogic,
  handleE2eRoutingLogic,
  handleE2eUnauthenticatedRoutingLogic,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { Logo } from 'src/e2e-redesign/components/LogoGrid';
import { ComponentLogicI } from 'src/e2e-redesign/interfaces/e2e-base-interfaces';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { E2ERoutes, NewE2ERoutes } from 'src/enums/routes.enums';
import { E2eGlobalStore } from 'src/interfaces/e2e-state.interfaces';
import { routeTransitionMap } from 'src/interfaces/transition-maps';
import { fsTrackEvent } from 'src/utils/fullstory';

export const welcomeLogic_init = (routingPackage: E2eLogicObjectsWithParams) => {
  const params = routingPackage.params;
  const { store_uuid, e2e_form_id, store_name } = params;
  const { e2eStore, auth } = routingPackage;
  const { isAuthenticated } = auth;
  const baseCallDetails: E2eBaseCallDetailsI = {
    store_uuid: params.store_uuid,
    e2e_form_id: params.e2e_form_id,
  };
  // this runs if user is not authenticated
  e2eGetBasePublicRequests(e2eStore, baseCallDetails, isAuthenticated);
  // this runs if they are
  e2eGetBaseAuthenticatedRequests(baseCallDetails, e2eStore, isAuthenticated);

  useEffect(() => {
    if (store_uuid && store_name && e2e_form_id) {
      e2eStore.initApplicationStore(store_uuid, store_name, e2e_form_id);
    }
  }, []);
};

export interface WelcomLogicI extends ComponentLogicI {
  fn: {
    handleLogin: () => void;
    handleAASORedirect: () => void;
    handleStartNew: () => void;
    handleContinue: (continueRoute: any | undefined, continueAASO: any) => void;
    handleCreateAccount: () => void;
  };
  v: {
    continueAASO: any;
    continueRoute: string;
    openContinueApp: boolean;
    disclosureLogos: Logo[];
  };
}

export const welcomLogic_funcAndV = (routingPackage: E2eLogicObjectsWithParams): WelcomLogicI => {
  const { params, auth } = routingPackage;
  const { e2e_form_id, store_uuid, store_name } = params;
  const createAaso = useE2eStore((state: E2eGlobalStore) => state.createAaso);
  const setAaso = useE2eStore((state: E2eGlobalStore) => state.setAASO);
  const user = auth?.user;
  const isAuthenticated = auth?.isAuthenticated;
  const e2eStore = routingPackage.e2eStore;
  const app_configs = e2eStore.app_configurations;
  const [openContinueApp, setOpenContinueApp] = useState<boolean>(false);
  const [continueRoute, setContinueRoute] = useState<any>();
  const [disclosureLogos, setDisclosureLogos] = useState<Logo[]>([]);
  const [continueAASO, setContinueAASO] = useState<any>();

  useEffect(() => {
    if (app_configs?.lender_ids) {
      const dls = e2eConfigLogic_buildStoreLenderLogoObjects(
        app_configs,
        e2eStore.disclosures,
        app_configs?.lender_ids,
      );
      setDisclosureLogos(dls);
    }
  }, [app_configs, e2eStore.disclosures]);

  useEffect(() => {
    if (app_configs?.store_details) {
      fsTrackEvent('Welcome Page Landing', {
        store_name,
        store_uuid,
        store_id: app_configs?.store_details?.id,
      });
    }
  }, [app_configs]);

  const handleCreateAccount = async () => {
    if (isAuthenticated) {
      await handleAASORedirect();
    } else {
      handleCreateAccountRoutingLogic(routingPackage, {
        currentStage: ApplicationStage.STARTED,
        currentE2eRoute: NewE2ERoutes.CreateAccount,
      });
    }
  };

  const handleShowContinue = (continueRoute: string, continueAASO: any) => {
    setContinueAASO(continueAASO);
    setOpenContinueApp(true);
    setContinueRoute(continueRoute);
  };

  const handleContinue = (continueRoute: any | undefined, continueAASO: any) => {
    const newRoutingPackage = {
      ...routingPackage,
      aasoContext: { aaso: continueAASO },
    };
    setAaso(continueAASO);
    const routingArgs: E2eRoutingArgsI = {
      currentStage: continueAASO.current_stage,
      currentE2eRoute: E2ERoutes.Welcome, // this doesn't matter
      aaso: continueAASO,
      continueRoute,
      continue: true,
    };
    handleE2eRoutingLogic(newRoutingPackage, routingArgs, continueAASO);
  };

  const handleStartNew = async () => {
    console.log('HANDLE START NEW');
    if (store_uuid && e2e_form_id && user) {
      const generatedAASO = await createAaso(user, store_uuid, e2e_form_id);
      const routingArgs: E2eRoutingArgsI = {
        currentStage: ApplicationStage.STARTED,
        currentE2eRoute: E2ERoutes.Welcome,
      };
      handleE2eRoutingLogic(routingPackage, routingArgs, generatedAASO);
    }
  };

  const handleAASORedirect = async () => {
    if (user) {
      let AASO;
      try {
        if (store_uuid && e2e_form_id) {
          // check to see if an existing aaso already exists
          AASO = await apiRequest(API_E2E_GET_AASO_BY_APPLICANT, {
            params: { applicant_id: user?.id, store_uuid, e2e_form_id },
          });
        }
      } catch (e) {
      } finally {
        // aaso does not exists, create new
        if (!AASO?._id) return handleStartNew();

        if (AASO?._id) {
          // aaso already exists
          const routeFromStage = routeTransitionMap[AASO.current_stage as keyof typeof routeTransitionMap];
          if (routeFromStage) {
            return handleShowContinue(routeFromStage, { ...AASO });
          }
        } else {
          console.log('SHOULD NOT SEE - to dashboard');
          // navigate('dashboard');
        }
      }
    }
  };

  const handleLogin = () => {
    if (isAuthenticated) {
      return handleAASORedirect();
    }
    const routingArgs: E2eRoutingArgsI = {
      currentStage: ApplicationStage.STARTED,
      currentE2eRoute: E2ERoutes.Welcome,
    };
    handleE2eUnauthenticatedRoutingLogic(routingPackage, routingArgs);
  };

  return {
    fn: {
      handleLogin,
      handleAASORedirect,
      handleStartNew,
      handleContinue,
      handleCreateAccount,
    },
    v: {
      continueAASO,
      continueRoute,
      openContinueApp,
      disclosureLogos,
    },
  };
};
