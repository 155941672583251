import { isEmpty } from 'lodash';
import { e2eLogicUtils_addUniquePastPath } from 'src/e2e-redesign/business-logic/e2e-logic-utils';
import {
  E2eRoutingArgsI,
  E2eRoutingLogicI,
  handleE2eFormSubmittedRoutingLogic,
} from 'src/e2e-redesign/business-logic/e2e-routing-logic';
import { NewE2ERoutes } from 'src/enums/routes.enums';
import { AASO } from 'src/interfaces/aaso.interfaces';
import {
  ApplicationSettingsI,
  E2eAppSettingsFormPathsI,
  E2eAppSettingsFormsI,
  E2eAppSettingsLenderDetailsI,
} from 'src/interfaces/application-settings.interfaces';
import { E2eGlobalStore, E2eProcessApplicationJobI } from 'src/interfaces/e2e-state.interfaces';
import { E2eConfigI, LendersI } from 'src/interfaces/lenders.interfaces';
import { ApplicationSubmissionStateDto, AppSubLenderResponseE } from 'src/interfaces/submissions.interfaces';
import { fsTrackEvent } from 'src/utils/fullstory';

export const e2eLogic_DoMultiplePathsExist = (appSettings: ApplicationSettingsI, e2e_form_id: string) => {
  const e2eForm = appSettings?.e2e?.e2e_forms?.find((form) => form.id === e2e_form_id);
  let count = 0;
  if (e2eForm) {
    for (const path of e2eForm.paths) {
      if (path.enabled) {
        count++;
      }
    }
  }
  return count > 1;
};

export const e2eLogic_getCurrentE2eForm = (
  appSettings: ApplicationSettingsI,
  e2e_form_id: string,
): E2eAppSettingsFormsI | undefined => {
  if (appSettings && appSettings.e2e) {
    return appSettings?.e2e?.e2e_forms.find((form) => form?.id === e2e_form_id);
  }
};

export const e2eLogic_checkForSubmissionRedirect = (e2eJobs: E2eProcessApplicationJobI[]): boolean => {
  for (const submission of e2eJobs) {
    if (!isEmpty(submission.redirect_details)) {
      return true;
    }
  }
  return false;
};

export const e2eLogic_getLenderRedirects = (e2eJobs: E2eProcessApplicationJobI[]): E2eProcessApplicationJobI[] => {
  const obj: E2eProcessApplicationJobI[] = [];
  for (const job of e2eJobs) {
    if (!isEmpty(job.redirect_details)) {
      obj.push(job);
    }
  }
  return obj;
};
export const e2eLogic_checkRedirectCompleted = (
  e2eJobs: E2eProcessApplicationJobI[],
  submissions: ApplicationSubmissionStateDto[],
): E2eProcessApplicationJobI[] => {
  const finishedE2eJobs: E2eProcessApplicationJobI[] = [];
  for (const sub of submissions) {
    // const completedStatuses = [
    //   ApplicationSubmissionStateEnums.completed,
    //   ApplicationSubmissionStateEnums.error,
    //   ApplicationSubmissionStateEnums.lender_responded,
    // ];
    // console.log(completedStatuses);
    const validLenderResponses = [
      AppSubLenderResponseE.hold,
      AppSubLenderResponseE.nr,
      AppSubLenderResponseE.pending,
      AppSubLenderResponseE.pre_approved,
      AppSubLenderResponseE.invalid,
      AppSubLenderResponseE.e2e_pre_approved,
      AppSubLenderResponseE.rejected,
      AppSubLenderResponseE.soft_decline,
      AppSubLenderResponseE.approved,
    ];
    if (sub.redirected_to_provider) {
      if (validLenderResponses.includes(sub.lender_response)) {
        // find e2eJobs redirected
        const found = e2eJobs.find((job) => Number(job.lender_id) === Number(sub.lender_id));
        if (found) {
          finishedE2eJobs.push(found);
        }
      }
    }
  }
  return finishedE2eJobs;
};

export interface RedirectedOffersI {
  lender_id: number;
  redirect_url: string;
  fp_unique_id: string;
}
export const e2eLogic_redirectUser = (
  submission: ApplicationSubmissionStateDto[],
  submittedTabs: RedirectedOffersI[],
  currentPath?: E2eAppSettingsFormPathsI,
): RedirectedOffersI[] => {
  const redirectedOffersI: RedirectedOffersI[] = [];
  const params = new URLSearchParams(location.search);
  if (currentPath) {
    for (const sub of submission) {
      if (sub?.redirected_to_provider && sub?.lender_offers) {
        const currentPathLenders = currentPath.lenders;
        if (currentPathLenders.includes(Number(sub.lender_id))) {
          // were inside the current path, we can run
          for (const offer of sub.lender_offers) {
            if (offer.redirect_url) {
              // now make sure we don't already have a tab open
              // const alreadyExists = submittedTabs.find((tab) => tab.fp_unique_id === offer.fp_unique_id);
              // const lenderId = params.get(`lenderId${sub.lender_id}`);
              // const alwaysRedirectEnvs = ['production', 'development'];
              // const env = process.env.REACT_APP_ENVIRONMENT || '';
              // console.log(env);
              /**
               * We are removing the auto-redirect for now bc it's jsut causing too many
               * issues and it's not even working for most users
               */
              // if (lenderId === null || lenderId !== 'true') {
              // if (!alreadyExists) {
              //   window.open(offer.redirect_url, '_blank');
              // }
              // window.open(offer.redirect_url, '_blank');
              redirectedOffersI.push({
                lender_id: Number(sub.lender_id),
                redirect_url: offer.redirect_url,
                fp_unique_id: offer?.fp_unique_id ? offer.fp_unique_id : '',
              });
              // }
              //
              // if (alwaysRedirectEnvs.includes(env)) {
              //   // always redirect on prod and dev
              //   console.log('AUTO-REDIRECT');
              //   if (!alreadyExists) {
              //     window.open(offer.redirect_url, '_blank');
              //   }
              //   redirectedOffersI.push({
              //     lender_id: Number(sub.lender_id),
              //     redirect_url: offer.redirect_url,
              //     fp_unique_id: offer?.fp_unique_id ? offer.fp_unique_id : '',
              //   });
              // } else {
              //   if (lenderId === null || lenderId !== 'true') {
              //     if (!alreadyExists) {
              //       window.open(offer.redirect_url, '_blank');
              //     }
              //     window.open(offer.redirect_url, '_blank');
              //     redirectedOffersI.push({
              //       lender_id: Number(sub.lender_id),
              //       redirect_url: offer.redirect_url,
              //       fp_unique_id: offer?.fp_unique_id ? offer.fp_unique_id : '',
              //     });
              //   }
              // }
            }
          }
        }
      }
    }
  }
  return redirectedOffersI;
};

export const e2eLogic_getCurrentE2eFormPath = (
  aaso: AASO,
  e2eForm?: E2eAppSettingsFormsI,
): E2eAppSettingsFormPathsI | undefined => {
  if (e2eForm && aaso.current_path_id !== undefined) {
    return e2eForm?.paths.find((path) => path.id === aaso.current_path_id);
  }
};

export const e2eLogic_setPathIndex = (
  e2eStore: E2eGlobalStore,
  updateAaso: any,
  e2e_form_id: string,
  // pathIndex?: number,
) => {
  const aaso = e2eStore.aaso;
  if (!aaso?.current_path_id) {
    // current_path_id is not set, we need to set it - find first available path.enabled
    const e2eForm = e2eStore.application_settings?.e2e?.e2e_forms?.find((form) => form.id === e2e_form_id);
    const firstEnabledPath = e2eForm?.paths.find((path) => path.enabled);
    const pathIndex = firstEnabledPath ? e2eForm?.paths.indexOf(firstEnabledPath) : undefined;

    if (firstEnabledPath) {
      updateAaso({
        data: {
          path_index: pathIndex,
          current_path_id: firstEnabledPath?.id,
        },
        sync: true,
      });
      return;
    }
  } else {
    return;
  }
  throw new Error('Could not set path index');
};

export const e2eLogic_handleSubmittedForm = async (
  formValues: any,
  routingPackage: E2eRoutingLogicI,
  setOpenModal: any,
  forceAddressVerification: boolean,
  setModalAddress: any,
) => {
  const { e2eStore, aasoContext } = routingPackage;
  const aaso = aasoContext.aaso;
  const setFormValues = e2eStore.setFormValues;
  const updateAaso = e2eStore.updateAASO;
  const currentPathId = aaso?.current_path_id;
  const currentForm = e2eStore?.app_configurations?.current_form;
  if (aaso && currentForm && currentPathId) {
    const routingArgs: E2eRoutingArgsI = {
      currentStage: aaso.current_stage,
      currentE2eRoute: NewE2ERoutes.Application, // this doesn't matter
    };
    // save the form values
    const res = await e2eStore.updateFormValues(formValues, forceAddressVerification);
    if (res.success) {
      fsTrackEvent('Form Submitted', {
        form_id: res?.form_id,
      });
      const aasoPastPathIds = aaso.past_path_ids;
      const aasoPastPathIndex = aaso.past_path_index;
      const updatedPaths = e2eLogicUtils_addUniquePastPath(
        aasoPastPathIds,
        aasoPastPathIndex,
        currentForm.paths,
        currentPathId,
      );
      formValues.id = res.form_id;
      setFormValues({ data: { ...formValues }, submitted: true });
      updateAaso({
        data: {
          form_id: String(res.form_id),
          past_path_ids: updatedPaths.past_path_ids,
          past_path_index: updatedPaths.past_path_index,
        },
        sync: true,
      });
      handleE2eFormSubmittedRoutingLogic(routingPackage, routingArgs);
    } else if (res.statusMessage === 'Invalid Address provided' && !forceAddressVerification) {
      setOpenModal(true);
    } else if (res.statusMessage === 'CheckAddress') {
      setOpenModal(true);
      setModalAddress(res.address);
    }
  }
};

export const e2eLogic_lenderE2eEnabled = (
  lenderE2eConfig: E2eConfigI,
  pathLenderDetails: E2eAppSettingsLenderDetailsI,
): boolean => {
  return !!(lenderE2eConfig?.is_e2e && lenderE2eConfig?.enabled && pathLenderDetails?.is_e2e);
};

export const e2eLogic_lenderE2eCanAutoSubmit = (
  lenderE2eConfig: E2eConfigI,
  pathLenderDetails: E2eAppSettingsLenderDetailsI,
): boolean => {
  return lenderE2eConfig?.auto_submit && pathLenderDetails?.auto_submit;
};

export const e2eLogic_lenderE2eShouldDisplayOffers = (
  lenderE2eConfig: E2eConfigI,
  pathLenderDetails: E2eAppSettingsLenderDetailsI,
) => {};
export const e2eLogic_getValidE2eLenders = (
  lenderObjs: LendersI[],
  currentPath: E2eAppSettingsFormPathsI | undefined,
  includeAutoSubmit?: boolean,
): number[] => {
  // TODO ALEX - update is E2E here
  const e2eLenders = [];
  if (currentPath) {
    const lenders = currentPath.lenders;
    for (const l of lenders) {
      if (lenderObjs && lenderObjs.length > 0) {
        const found = lenderObjs.find((len) => Number(len.lender_id) === Number(l));
        if (found) {
          const apiPipeSettings = found?.api_pipe_settings;
          const lenderE2eConfig = apiPipeSettings?.e2e_config;
          const pathLenderDetails = currentPath?.lender_details?.find((pld) => Number(pld.lender_id) == Number(l));
          if (lenderE2eConfig && pathLenderDetails) {
            const validE2eLender = e2eLogic_lenderE2eEnabled(lenderE2eConfig, pathLenderDetails);
            if (validE2eLender) {
              const autoSubmit = e2eLogic_lenderE2eCanAutoSubmit(lenderE2eConfig, pathLenderDetails);
              if (includeAutoSubmit) {
                if (autoSubmit) {
                  e2eLenders.push(l);
                }
              } else {
                e2eLenders.push(l);
              }
            }
          }

          // if (lenderE2eConfig?.is_e2e && lenderE2eConfig?.enabled) {
          //   if (includeAutoSubmit) {
          //     if (lenderE2eConfig?.auto_submit) {
          //       e2eLenders.push(l);
          //     }
          //   } else {
          //     e2eLenders.push(l);
          //   }
          // }
        }
      }
    }
  }
  return e2eLenders;
};

export const e2eLogic_getValidE2eLendersForAutoApproval = (
  lenderObjs: LendersI[],
  currentPath: E2eAppSettingsFormPathsI | undefined,
): number[] => {
  // TODO ALEX - update is E2E here
  const e2eLenders = [];
  if (currentPath) {
    const lenders = currentPath.lenders;
    for (const l of lenders) {
      if (lenderObjs && lenderObjs.length > 0) {
        const found = lenderObjs.find((len) => Number(len.lender_id) === Number(l));
        if (found) {
          const apiPipeSettings = found?.api_pipe_settings;
          const lenderE2eConfig = apiPipeSettings?.e2e_config;
          const pathLenderDetails = currentPath?.lender_details?.find((pld) => Number(pld.lender_id) == Number(l));
          if (lenderE2eConfig && pathLenderDetails) {
            const validE2eLender = e2eLogic_lenderE2eEnabled(lenderE2eConfig, pathLenderDetails);
            if (validE2eLender) {
              const autoSubmit = e2eLogic_lenderE2eCanAutoSubmit(lenderE2eConfig, pathLenderDetails);
              if (autoSubmit) {
                e2eLenders.push(l);
              }
            }
          }

          // old
          // if (lenderE2eConfig?.is_e2e && lenderE2eConfig?.enabled) {
          //   if (lenderE2eConfig?.auto_approval?.enabled) {
          //     e2eLenders.push(l);
          //   }
          // }
        }
      }
    }
  }
  return e2eLenders;
};
