import React, { useCallback, useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import toast from 'react-hot-toast';
import useE2eStore from 'src/e2e-store';
import { ApplicationStage } from 'src/enums/aaso.enums';
import { useAuth } from 'src/hooks/use-auth';
import { Issuer } from 'src/types/auth';

export default function MainHeader() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const auth = useAuth();
  const isAuthenticated = auth.isAuthenticated;

  const submissions = useE2eStore((store) => store.submissions);
  const aaso = useE2eStore((store) => store.aaso);

  const storeName = aaso?.store_name;
  const storeUuid = aaso?.store_uuid;
  const e2eFormId = aaso?.e2e_form_id;
  const aasoId = aaso?._id;
  let offerUrl;
  if (aaso?.kick_off_details && aaso.kick_off_details.length > 0) {
    if (!storeName || !storeUuid || !e2eFormId || !aasoId) {
      console.error('Missing required parameters for offer URL');
    } else {
      offerUrl = `/${storeName}/${storeUuid}/${e2eFormId}/${aasoId}/provider-responses`;
    }
  }
  if (storeName && storeUuid && e2eFormId && aasoId) {
    offerUrl = `/${storeName}/${storeUuid}/${e2eFormId}/${aasoId}/provider-responses`;
  }
  const handleMenuOpen = () => setIsMenuOpen(true);
  const handleMenuClose = () => setIsMenuOpen(false);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      switch (auth.issuer) {
        case Issuer.JWT: {
          await auth.signOut();
          break;
        }
        default: {
          console.warn('Using an unknown Auth Issuer, did not log out');
        }
      }
      window.location.reload();
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  }, [auth]);

  let backgroundImage;
  if (isMobile) {
    backgroundImage = `url('/assets/e2e-redesign/images/mobile-header.png')`;
  } else if (isTablet) {
    backgroundImage = `url('/assets/e2e-redesign/images/tablet-header.png')`;
  } else if (isDesktop) {
    backgroundImage = `url('/assets/e2e-redesign/images/desktop-header.png')`;
  }

  return (
    <Box
      component="header"
      sx={{
        backgroundColor: '#ffffff',
        backgroundImage: backgroundImage,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: { xs: '150px', sm: '200px' },
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        position: 'relative',
      }}
    >
      <Box
        component="img"
        src="/assets/e2e-redesign/images/white-logo.png"
        alt="FormPiper Logo"
        sx={{
          marginTop: '20px',
          marginRight: '20px',
          paddingBottom: '0',
          width: '121px',
          height: 'auto',
          alignSelf: 'flex-start',
        }}
      />
      {isAuthenticated && (
        <IconButton onClick={handleMenuOpen} sx={{ marginTop: '20px', marginRight: '20px' }}>
          <MenuIcon />
        </IconButton>
      )}
      <Modal
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{
          position: 'absolute',
          top: '60px',
          right: '20px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '300px',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            borderRadius: '10px',
            color: theme.palette.primary.main,
          }}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  color: theme.palette.primary.main,
                  justifyContent: 'flex-end',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                component="a"
                href="/dashboard"
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '1.2rem',
                    textAlign: 'right',
                  }}
                >
                  MyFormPiper Dashboard
                </Typography>
              </ListItemButton>
            </ListItem>
            {(aaso.kick_off_details && aaso.kick_off_details.length > 0) ||
            aaso?.current_stage === ApplicationStage.FORM_SUBMITTED ? (
              <ListItem disablePadding>
                <ListItemButton
                  sx={{
                    color: theme.palette.primary.main,
                    justifyContent: 'flex-end',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  component="a"
                  href={offerUrl}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontSize: '1.2rem',
                      textAlign: 'right',
                    }}
                  >
                    MyFormPiper Offers
                  </Typography>
                </ListItemButton>
              </ListItem>
            ) : null}
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  color: theme.palette.primary.main,
                  justifyContent: 'flex-end',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={handleLogout}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: '1.2rem',
                    textAlign: 'right',
                  }}
                >
                  Log out
                </Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Modal>
    </Box>
  );
}
