import React, { useEffect, useState } from 'react';
import { GroupedOfferI } from 'src/e2e-redesign/views/LenderResponses/logic/provider-response-logic';
import NewDumbLenderResponseTable from 'src/e2e-redesign/views/LenderResponses/new-dumb-lender-response-table';
import { LendersI } from 'src/interfaces/lenders.interfaces';
import {
  ApplicationContractStatusE,
  ApplicationSubmissionStateDto,
  AppSubLenderResponseE,
  LenderOffersI,
} from 'src/interfaces/submissions.interfaces';
import { queryE2eForm } from 'src/api/query-logic/query-e2e-form';
import { queryApplicationResults } from 'src/api/query-logic/query-dashboard';

interface E2eDashboardResponseHandlerProps {
  groupedOffer: GroupedOfferI;
  index: number;
}

const E2eDashboardResponseHandler: React.FC<E2eDashboardResponseHandlerProps> = (
  props: E2eDashboardResponseHandlerProps,
) => {
  const { groupedOffer, index } = props;
  const offer = groupedOffer.offers[0];
  const [showEcoa, setShowEcoa] = useState<boolean>(false);
  const offerDetails: LenderOffersI | undefined = offer?.offer_details;
  const submissionDetails: ApplicationSubmissionStateDto = offer.sub_details;
  const offerLender: LendersI = offer.lender;
  const lenderDisclosures = offer.lender_disclosures;
  const [selectedOffer, setSelectedOffer] = useState<string>('');
  const isPending = submissionDetails.lender_response === AppSubLenderResponseE.pending;
  const isSigned = submissionDetails.contract_status === ApplicationContractStatusE.signed ||
    submissionDetails.contract_status === ApplicationContractStatusE.funded;
  //TODO We don't have a good way of identifying app result by applicant so I am doing it per lender with offers here. this and the server needs rewritten to get all app_results for an applicant
  const { data } = queryApplicationResults({
    reqData: { params: { lender_id: submissionDetails.lender_id, fp_unique_id: offerDetails?.fp_unique_id } },
    queryKey: [`${submissionDetails.lender_id}-${offerDetails?.fp_unique_id}`],
    enabled: isSigned,
  });

  useEffect(() => {
    const showEcoaStatuses = [AppSubLenderResponseE.rejected, AppSubLenderResponseE.soft_decline];
    if (showEcoaStatuses.includes(submissionDetails.lender_response)) {
      setShowEcoa(true);
    }
  }, [submissionDetails]);



  return (
    <>
      <NewDumbLenderResponseTable
        submissionDetails={submissionDetails}
        index={index}
        offerLender={offerLender}
        lenderDisclosures={lenderDisclosures}
        offerDetails={offerDetails}
        offer={offer}
        applicationResult={data}
        isPending={isPending}
        selectedOffer={selectedOffer}
        showEcoa={showEcoa}
        // not including
        redirectUrl={undefined}
        showUpdateStatus={false}
        handleUpdateStatus={async () => {}}
        handleAcceptAndProceed={() => {}}
        handleOfferSelection={() => {}}
        disableActions={true}
      />
    </>
  );
};

export default E2eDashboardResponseHandler;
