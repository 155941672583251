import React from 'react';
import { Seo } from 'src/components/seo';

import PageIntro from '../../components/PageIntro';
import ThemedContainer from '../../components/ThemedContainer';

export default function CommunicationNotice() {
  // console.log('New Communications Notice Page');
  // R:TODO E2E P0 - I think we can remove this

  return (
    <>
      <Seo title="Communication Notice" />
      <ThemedContainer>
        <PageIntro
          title="Communication Notice"
          paragraph="By checking the boxes below, you hereby consent to FormPiper's access to and use of the phone number and email address you provide in conjunction with your use of the FormPiper Platform for security purposes."
        />
        {/* <Formik initialValues={initialValues} onSubmit={handleSubmit}> */}
        {/*   {({ isSubmitting }) => <CommunicationForm isSubmitting={isSubmitting} />} */}
        {/* </Formik> */}
      </ThemedContainer>
    </>
  );
}
