import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { parseHtml } from 'src/utils/general';

interface AccordionMessageProps {
  title: string;
  content: string;
}

const AccordionMessage: React.FC<AccordionMessageProps> = ({ title, content }) => {
  return (
    <Accordion elevation={0} sx={{ 
        border: 'none', 
        boxShadow: 'none', 
        '&:before': {  // This removes the divider line
          display: 'none',
        },
      }}  disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${title}-helper-content`} id={`${title}-helper-header`}>
        <Typography variant="body2" color="text.primary">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2" component="div">
          {parseHtml(content)}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionMessage;