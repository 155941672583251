import { useQuery } from '@tanstack/react-query';

import { QueryTypes } from '../query.constants';
import { apiRequest, apiRequestSync } from '../request-handler';
import { API_GET_DASHBOARD_DATA } from '../request-objects';
import { TsQueryI } from 'src/api/query-logic/query-interfaces';
import { createQueryKey } from 'src/api/query-logic/query-utils';
import { API_E2E_GET_APP_RESULTS, API_E2E_GET_APPLICANT_FORM } from 'src/api/e2e-request-objects';
import { ApplicationResultsI } from 'src/interfaces/application.interfaces';

export const queryDashboardData = () => {
  return useQuery({
    /**
     * this is the key used to invalidate cache (if the data changes, we want to invalidate it so
     * it gets the latest)
     */
    queryKey: [`${QueryTypes.DASHBOARD_DATA}`],
    queryFn: () => apiRequestSync(API_GET_DASHBOARD_DATA, {}),
    // provides initial data
    initialData: {},
    /**
     * if this is not set to false,
     * it will call every single time the user
     * focuses on that page
     */
    refetchOnWindowFocus: false,
  });
};

export const queryApplicationResults = (obj: TsQueryI) => {
  return useQuery<ApplicationResultsI, Error>({
    queryKey: createQueryKey('get-application-results', obj.queryKey),
    queryFn: async (): Promise<ApplicationResultsI> => {
      const res = await apiRequest(API_E2E_GET_APP_RESULTS, obj.reqData);
      obj.setState && obj.setState(res);
      return res;
    },
    staleTime: 5 * 60 * 1000,
    ...obj.queryOpt,
    enabled: obj.enabled !== undefined ? obj.enabled : true,
    // refetchInterval: 0,
    // gcTime: 0,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
